import React from "react";
import Bestsellers from "../../layout/Bestsellers/Bestsellers";
import Header from "../../layout/Header/Header";

const Homepage = () => {
  return (
    <>
      <Header />
      <Bestsellers />{" "}
    </>
  );
};

export default Homepage;
