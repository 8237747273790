import React from "react";
import styles from "./Numbers.module.scss";
import CountUp from "react-countup";
const Numbers = () => (
  <div className={styles.container}>
    <div className={styles.box}>
      <h1>
        <CountUp start={0} end={3500} duration={2.75} />+
      </h1>
      <h6>Satisfied Customers</h6>
    </div>
    <div className={styles.box}>
      <h1>0%</h1>
      <h6>Ban Ratio</h6>
    </div>
    <div className={styles.box}>
      <h1>
        <CountUp start={0} end={1200} duration={2.75} />+
      </h1>
      <h6>Hours of Coding</h6>
    </div>
  </div>
);

export default Numbers;
