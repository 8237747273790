import React, {useState} from "react";
import styles from "./Bestsellers.module.scss";
import Zoom from "react-reveal/Fade";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
import SingleProduct from "../../features/SingleProduct/SingleProduct";
import productsData from "../../../data";

const Bestsellers = () => {
  const [products, setProducts] = useState(productsData.products);

  const product = products.map((product) => {
    return product.bestseller ? (
      <SingleProduct
        key={product._id}
        id={product._id}
        name={product.name}
        product_image={product.product_image}
        status_1={product.status_1}
        status_2={product.status_2}
        description={product.description}
        starting_price={product.starting_price}
        category={product.category}
        subscriptions={product.subscriptions}
      />
    ) : null;
  });
  return (
    <div id="products" className={styles.container}>
      <div className={styles.title}>
        <FontAwesomeIcon icon={faCrown} />
        <h1>BESTSELLERS</h1>
        <p>the best products on market</p>
      </div>
      <Zoom top>
        <div className={styles.row}>
          {product}
        </div>
      </Zoom>
      <div className={styles.row}>
        <a href="https://hitmansservices.com/#/products/">
          <div className={styles.small_box}>
            <div className={styles.text}>
              <p>Show More</p>
              <FontAwesomeIcon icon={faAngleDoubleRight} />
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Bestsellers;
