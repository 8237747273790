import React, { useEffect, useState } from "react";
import styles from "./Breadcrumb.module.scss";
import { NavLink, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Breadcrumb = ({
  category,
  categoryLink,
  productName,
  productLink,
  productId,
}) => {
  const [isProductPage, setProductPage] = useState(false);
  const location = useLocation();
  
  useEffect(() => {
    if (location.pathname.includes(`/products/${productId}`)) {
      setProductPage(true);
    } else {
      setProductPage(false);
    }
  }, [location]);
  return (
    <div className={styles.container}>
      <NavLink
        to="/"
        end
        className={(navData) => (navData.isActive ? `${styles.active}` : null)}
      >
        Home
      </NavLink>
      <p>{">"}</p>
      <NavLink
        to="/products/"
        // className={(navData) => (navData.isActive ? `${styles.active}` : null)}
      >
        Products
      </NavLink>
      <p>{">"}</p>

      <NavLink to={categoryLink}  className={(navData) => (navData.isActive ? `${styles.active}` : null)}>
        {category}
      </NavLink>
      <p>{">"}</p>
      {isProductPage ? (
        <NavLink to={productLink} className={styles.active}>
          {productName}
        </NavLink>
      ) : null}
    </div>
  );
};

export default Breadcrumb;
