import { useEffect } from "react";
import styles from "./MainLayout.module.scss";
import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer";
import Snow from "../../christmas/Snow";
import Lights from "../../christmas/Lights";

const MainLayout = ({ children }) => {

  return (
    <>
      {/* <Snow />
      <Lights /> */}
      <main className={styles.container}>
        <div className={styles.subcontainer}>
          <Navigation />
          {children}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default MainLayout;
