const faq = [
  {
    category: "general",
    question: "1. Do you have any disclaimers? ",
    answer:
      "Yes, before you buy it’s important to know, we don’t do refunds. If you are to have any issues, you’ll need to reach out to us, by joining our discord and opening a ticket, where we can discuss and support you as much as possible, to solve any issues you have.",
  },
  {
    category: "general",
    question:
      "2. If I’m unable to pay on the website, Can I pay any other way?",
    answer:
      "Yes, we take PayPal or Cashapp. To make a payment using one of these options, you will need to join our discord and open a ticket, informing us you’d like to pay with PayPal or Cashapp.",
  },
  {
    category: "general",
    question:
      "3. When I go to download the tool, why does my browser block the download with the error ‘Virus detected’?",
    answer:
      "To download the tool, you’ll need to disable your windows security, Firewall and app and browser control. You’ll then be able to download by selecting keep if this pops up.",
  },
  {
    category: "general",
    question:
      "4. When I enter my key into the Hitman loader, why does an error message ‘Invalid HWID’ appear?",
    answer:
      "Our keys are Hwid locked. Therefore, if your to use your key on another system, do a fresh windows install or anything else that changes your hwid serials, we will need to do a HWID reset On your key. You can do this by opening a ticket in our discord server and sending your key Informing us about your hwid reset.",
  },
  {
    category: "general",
    question:
      "5. When I enter my key into the Hitman loader, an error message appears ‘invalid key. Why is this?",
    answer:
      "Please make sure when you enter your key, there are no spaces before or After. Also make sure to enter the full key.",
  },
  {
    category: "general",
    question: "6. Can I use your tools/services on console?",
    answer: "No, they are only able to be used on PC.",
  },
  {
    category: "general",
    question:
      "7. Do I have to use a spoofer when it comes to using your tools?",
    answer:
      "This is personal choice. You can use our tools with or without a spoofer. However, we do recommend to use one, as this protects your hwid from receiving a hwid ban/hwid flag, or any bans to accounts you don’t want to lose. For example, if your to use our products on an alt account and you spoof every time before going on that alt account, if this account is to receive a ban, your hwid will remain clean as you spoofed, as well as any other accounts you have will remain safe.",
  },
  {
    category: "general",
    question: "8. My Hitman loader won’t open or inject, why is this?",
    answer:
      "Make sure your windows security, firewall, app and browser control are fully disabled. If you have any anti-virus software installed, we advise to uninstall as this can block the tool. Apps such as riot vanguard, Riot client, Riva tuna, wallpaper engine and anything with a strong anti-cheat can block the tool from opening. Therefore, we advise to uninstall if you run into any issues. Also disable any game overlays such as discord overlay, Nvidia overlay or wallpaper engine.",
  },
  {
    category: "general",
    question:
      "9. Why am I disconnecting from games after getting a certain amount of kills?",
    answer:
      "If your using a new account, not aged, its a commen occurance to disconnect from games after getting a certain amount of kills. THIS IS NOT BECAUSE OF OUR TOOLS! This is because Activision implented a DC on new accounts, as they assume your smurfing. Youlle need to use a aged account if you wish to get alot of kills or avoid being disconnected from games.",
  },
  {
    category: "hwid unban",
    question: "1. What is a HWID unban?",
    answer:
      "A HWID unban is a service provided by us, where a member of our team will manually change your hwid serials, so your pc becomes permanetley unbanned. Its almost like having a brand new pc again.",
  },
  {
    category: "hwid unban",
    question: "2. Is the HWID unban a tool?",
    answer:
      "No, it’s a service where someone will manually change your PC serials.",
  },
  {
    category: "hwid unban",
    question: "3. How long can a HWID unban service take? ",
    answer:
      "A HWID unban service can take anywhere from 30mins to an hour. If any issues occur, this can take longer.",
  },
  {
    category: "hwid unban",
    question: "4. How many unbans can I have per purchase? ",
    answer:
      "One purchase means One HWID unban service. Therefore, if you need to do it again in the future, you will have to re purchase.",
  },
  {
    category: "hwid unban",
    question: "5. What happens if the HWID unban isn’t successful?",
    answer:
      "We will re do the HWID unban for you free of charge. We do have a 100% success rate, however, if anything is to go wrong we will re do the process.",
  },
  {
    category: "hwid unban",
    question: "6. What is required for a HWID unban?",
    answer:
      "You will need a USB ready, minimum 8GB for a windows reinstall. You’ll also need to speak with one of us to check if your compatible for a hwid unban. We advise you do this before purchase in case you’re not compatible. You can do this by reaching out to us in our discord via ticket system.",
  },
  {
    category: "hwid unban",
    question:
      "7. Will I be able to use any of my old accounts after the HWID unban is done?",
    answer:
      "If you’ve decided to opt for a HWID unban, and are wanting to be unbanned to be legit and stay safe, we advice not to use any old accounts. This is because you dont want to pass anything over from your old banned hwid to your new clean hwid. However, if you intend to cheat and be safe, make sure to use a spoofer and spoof everytime you go on a account that you previously used, so your clean hwid doesnt become attatched to that account or banned.",
  },
  {
    category: "hwid unban",
    question:
      "8. What will happen if i decide to cheat and get banned again. Will i be able to get another hwid unban?",
    answer:
      "We do not take any accountability for your actions after a HWID unban is performed. Our job is to UNBAN your HWID. However, If you are to cheat and not take the right actions to keep your HWID clean, we will not perform a HWID unban free of charge. You will have to buy another service.",
  },
  {
    category: "CW UAV",
    question: "1. What is the worlds 1st cold war uav?",
    answer: "This is a permnanent UAV tool for cold war.",
  },
  {
    category: "CW UAV",
    question: "2. Does the CW uav work in hardcore?",
    answer:
      "No, CW uav only works in core modes. The reason being is due to hardcore not having a permanent radar.",
  },
  {
    category: "CW UAV",
    question:
      "3. Why am i getting a error messaging with regards to game having admin rights?",
    answer:
      "The game must NOT be ran as admin. To change this, youlle need to head over to your documents, go to the file where your game is, right click on the game file and select properties, then go to compatibililty tab, and untick run as administrator and apply changes. The tool should now work perfectly without any errors.",
  },
  {
    category: "CW UAV",
    question: "4. What mode should my game be in while using the UAV?",
    answer: "Borderless.",
  },
  {
    category: "CW UAV",
    question: "5. Is the CW uav stream proof?",
    answer:
      "No, If you were to stream whilst using the CW uav, this will show on your stream.",
  },
  {
    category: "CW UAV",
    question: "6. IF anyone spectates me, will they be able to see my UAV?",
    answer: "No, only ulle be able to see it.",
  },
  {
    category: "MW2/WZ2 CHEAT",
    question: "1. Is the MW2 chair stream proof?",
    answer:
      "Yes, youlle be able to stream your game to friends in discord and live on stream without your cheats showing.",
  },
  {
    category: "MW2/WZ2 CHEAT",
    question:
      "2. What mode should my game be played in whilst using this tool?",
    answer: "Make sure to be in fullscreen borderless.",
  },
  {
    category: "MW2/WZ2 CHEAT",
    question: "3. What button do i press to bring up the overlay and close?",
    answer: "INS.",
  },
  {
    category: "MW2/WZ2 CHEAT",
    question: "4. Why am i crashing during injection?",
    answer:
      "Make sure your game is in fullscreen borderless. Also make sure to inject at main menu. Youlle also need to disable all game overlays, including wallpaper engine if you have this on your pc. Make sure all windows security is disabled, including firewall and app and browser control. For further support on this we advise you join our discord server and make a ticket so we can resolve any issues.",
  },
  {
    category: "MW2/WZ2 CHEAT",
    question: "5. Is the MW2/WZ2 Priv cheat compatible with controller?",
    answer:
      "No, However, if your able to use a mapping software, such as REwasd, youlle be able to use controller.",
  },
  {
    category: "MW2 UAV",
    question: "1. Is the MW2 UAV stream proof?",
    answer:
      "No, if you were to use on stream in discord or live, your PERM UAV will show.",
  },
  {
    category: "MW2 UAV",
    question: "2. Does the MW2 UAV work in hardcore?",
    answer:
      "No, this will only work in core modes, as Hardcore doesnt have a permnanent radar.",
  },
  {
    category: "MW2 UAV",
    question: "3. What mode should my game be in?",
    answer: "Fullscreen borderless, to avoid crashes.",
  },
  {
    category: "MW2 UAV",
    question: "4. How do i close and bring up the overlay?",
    answer: "INS",
  },
  {
    category: "MW2 UAV",
    question: "5.Does the uav work in multiplayer?",
    answer:
      "Yes, the uav works both in wz and multiplayer. However, when it comes to Ranked MP, we advise to be cautious as ranked doesnt have a UAV.",
  },
  {
    category: "MW2 UAV",
    question: "6. Why am i experiencing crashing?",
    answer:
      "If your experiecing crashing, Please make sure to only enable the UAV in game, and disable before the match finishes. You can do this by pressing INS and ticking UAV enabled box when the match starts, and disabling by unticking the UAV enabled box before the match ends.",
  },
  {
    category: "MW2 UAV",
    question: "7. When a player is spectating me, can they see my PERM uav?",
    answer: "No, only you will be able to see the permanent uav.",
  },
  {
    category: "FN CHEAT",
    question: "1. Is the Fortnite chair streamproof?",
    answer:
      "Yes, youlle be able to stream to your friends in discord and live without the cheat showing.",
  },
  {
    category: "FN CHEAT",
    question: "2. Is the Fortnite chair compatible with controller?",
    answer:
      "No, however if your able to use a mapping software such as REwasd, you may be able to use controller.",
  },
  {
    category: "FN CHEAT",
    question: "3. How do i open and close the FN chair overlay?",
    answer: "Press INS.",
  },
  {
    category: "FN CHEAT",
    question: "4. Why isnt my overlay showing on injection?",
    answer:
      "Make sure to place the game in fullscreen borderless. Also discord overlay must be enabled.",
  },
  {
    category: "UNLOCK ALL",
    question: "1. Is this a hard unlock?",
    answer:
      "No, this is only a soft unlock. Once injected, everything will become unlocked. Youlle be able to make your classes with any camo, any attatchment. Once made youlle save the class as a custom mod. This will save your weapon as a blue print.",
  },
  {
    category: "UNLOCK ALL",
    question: "2. Am i able to play with the tool injected?",
    answer:
      "No. Its very important that you inject, save your classes as custom mods then restart your game. Going into any matches with the unlock all injected can lead to a shadow ban, then potentially a perm ban. Therefore YOU MUST NOT go into any games with the tool injected.",
  },
  {
    category: "UNLOCK ALL",
    question: "3. Why am i crashing during injection?",
    answer:
      "Make sure to place the game in fullscreen borderless. You must then press load at main menu. A small black and green box will then appear on your screen. You will then need to go to weapons tab, then press activate. Pressing activate at main menu will cause the game to crash. Therefore, make sure to press activate at weapons tab only. If you still experience crashing, please make sure to reach out to us in our discord, where you can make a ticket and recieve full support.",
  },
  {
    category: "UNLOCK ALL",
    question:
      "4. What do you mean by save the gun you have made as a blueprint?",
    answer:
      "Once you have made your class, at the bottom left of the screen youlle see 'save as custom mod'. You can save your gun as a blueprint by pressing this and creating your own blueprint. Your gun will then be saved in armoury, by going to weapons, selecting the gun, then at the end on the right your custom made gun will be saved here.",
  },
  {
    category: "UNLOCK ALL",
    question: "5. Am i able to use this on console?",
    answer:
      "No, you can only use the cheat on PC. However, you are able to use the account on console with your custom made classes/blueprints.",
  },
  {
    category: "UNLOCK ALL",
    question: "6. Am i able to unlock things for my friends?",
    answer:
      "You are not able to unlock things for your friends whilst using the cheat on your account. If your wanting to unlock anything for your friends, youlle need to be on there account.",
  },
  {
    category: "SB FIX&SPOOFER",
    question: "1. What is a spoofer/shadow ban fix?",
    answer:
      "A spoofer is a software that temporarily spoofs your hwid serials. Each pc has its own serials. Therefore, when you load up onto the COD servers, your hwid serials will be logged. A spoofer can be used in 2 different ways. One way is to protect your hwid. By spoofing each time before going onto an account, your hwid will be safe as the spoofed hwid will become attatched to that account. So if you were to recieve a perm ban, Your actual hwid will be safe from a hwid flag or hwid ban. Another way is if your hwid is flagged or banned, youlle be able to play on the COD servers as your hwid will be spoofed and youlle be playing on a different hwid. SPOOFERS ARE NOT MADE TO REMOVE SHADOW BANS. For a shadow ban you will have to wait for the ban to lift from your account.",
  },
  {
    category: "SB FIX&SPOOFER",
    question:
      "2. If i use the spoofer/shadow ban fix, will this remove a shadow ban from my account?",
    answer:
      "No. a spoofer does not remove a shadow ban. However, youlle be able to spoof and load up on a new account and play.",
  },
  {
    category: "SB FIX&SPOOFER",
    question: "3. Do you do lifetime subscriptions?",
    answer: "No, we only do monthly.",
  },
  {
    category: "SB FIX&SPOOFER",
    question:
      "4. What requirements are there to be able to use the Shadow ban fix/Spoofer?",
    answer:
      "You will need to have virtualisation enabled in bios, aswell as secure boot disabled. (Intel or AMD). This is also HWID Locked, therefore you will only be able to use on one system. For hwid resets, you will need to make a ticket in our discord server. If you wish to use on multiple systems, youlle need to purchase one shadow ban fix/spoofer per system.",
  },
  {
    category: "SB FIX&SPOOFER",
    question: "5. Can a spoofer/shadow ban fix remove a perm ban?",
    answer: "No. Once your account is banned this is final.",
  },
  {
    category: "SB FIX&SPOOFER",
    question: "6. How do i unspoof if i am spoofed?",
    answer:
      "You will need to restart your pc to unspoof. When you spoof, youlle be spoofed until a pc restart is performed.",
  },
  {
    category: "MW2 BOT LOBBY",
    question: "1. Is the MW2 bot lobby a tool?",
    answer:
      "No. After purchase youlle need to reach out to us, so we can arrange for you to get into a lobby thats hosted for you.",
  },
  {
    category: "MW2 BOT LOBBY",
    question: "2. Is this a hard unlock?",
    answer:
      "Yes. You will get on average 100-250 kills per game. Youlle gain alot of XP, Unlock camos, You can use a bot lobby for things like longshots or to complete certain challenges. Everything unlocked in a bot lobby will count and stick. No hacks are used, therefore this is completley undetectable.",
  },
  {
    category: "MW2 BOT LOBBY",
    question: "3. Am i able to bring my friends along with me?",
    answer: "They will need to pay also to recieve a bot lobby.",
  },
  {
    category: "MW2 BOT LOBBY",
    question: "4. Am i able to be on console and recieve a bot lobby?",
    answer: "Yes, as no tools are used.",
  },
  {
    category: "DA ZOMBIE TOOL",
    question: "1. Am i able to use this on console?",
    answer:
      "No, the tool can only be used on pc. However, you are able to host for your friends that are on console. They can join onto your lobby and recieve the camos, as youlle host the lobby for them.",
  },
  {
    category: "DA ZOMBIE TOOL",
    question: "2. What would be the safest way for me to use the tool?",
    answer:
      "We do highly recommend using a burner account to host a lobby. The only one at risk of a ban is the host. Therefore, if your wanting to be more safe, we would recommend you host on a burner account and join on a seperate platform to recieve the camos. Its also recomended to not leave the tool running for to long, therefore once the cycle is done, and cycled through all  the guns, you must press F6 then end the match to be safe.",
  },
  {
    category: "DA ZOMBIE TOOL",
    question: "3. What kind of subscriptions do you offer for the tool?",
    answer:
      "We offer Monthly and lifetime. For day or week keys, youlle need to make a ticket in our server if you would like to have these type of keys.",
  },
  {
    category: "DA ZOMBIE TOOL",
    question: "4.Can i use this tool to level up my account?",
    answer:
      "Not exactly, You can only earn max 100-200 levels. This tool was made to give camos not level up an account.",
  },
  {
    category: "DA ZOMBIE TOOL",
    question: "5. Is this a hard unlock or soft unlock?",
    answer: "This tool is hard unlock.",
  },
  {
    category: "DA ZOMBIE TOOL",
    question: "6. Do i need to do anything once the cycle has started?",
    answer:
      "No, Once activated in lobby by press F5, sit back and relax while the the tool cycles through your guns. Once complete, its important to press F6 and end the match.",
  },
];
export default faq;
