import React from "react";
import styles from "./SingleProduct.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function SingleProduct({
  id,
  product_image,
  name,
  status_1,
  status_2,
  starting_price,
  category,
  subscriptions,
}) {
  const singleCategory = category.map((cat) => cat.name);
  const pushedCategory = singleCategory.join(" & ");
  return (
    <>
      <div
        className={styles.box}
        style={{ backgroundImage: `url(${product_image})` }}
      >
        {/* <div className={`${styles.box} ${styles[product_class]}`}> */}
        <div className={styles.name}>
          <h2>{name}</h2>
          <p>Starts at £{starting_price}</p>
        </div>
        <div className={styles.game_logo}>
          <p>{pushedCategory}</p>
        </div>
        <div className={styles.bottom_bar}>
          <div className={styles.status_button}>
            <p>Status</p>
          </div>
          <div className={styles.buy_button}>
            <input type="checkbox" />
            <FontAwesomeIcon icon={faShoppingCart} />
            <div className={styles.buy_options}>
              {subscriptions.map((subscription) => {
                return (
                  <a
                    href={subscription.shoppy_link}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <div className={styles.option}>
                      <p>
                        {subscription.time} £{subscription.price}
                      </p>
                    </div>
                  </a>
                );
              })}
              {/* <div className={styles.option}>
              <a href={link_to_buy_1} rel="noreferrer" target="_blank">
                <p>{buy_option_1}</p>
              </a>
            </div>
            <div className={styles.option}>
              <a href={link_to_buy_2} rel="noreferrer" target="_blank">
                <p>{buy_option_2}</p>
              </a>
            </div> */}
            </div>
          </div>
          <Link to={`/products/${id}`}>
            <div className={styles.details_button}>
              <p>Details</p>
            </div>
          </Link>
          <div className={styles.status}>
            <div className={styles.row_text}>
              <FontAwesomeIcon icon={faCheck} />
              <p>{status_1}</p>
            </div>
            <div className={styles.row_text}>
              <FontAwesomeIcon icon={faCheck} />
              <p>{status_2}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

SingleProduct.defaultProps = {
  status1: "Active",
  status2: "Undetected",
  buy_option_1: "->",
};
export default SingleProduct;
