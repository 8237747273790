import React, { useState } from "react";
import styles from "./MyServices.module.scss";
import productsData from "../../../data";
import SingleProduct from "../../features/SingleProduct/SingleProduct";
import Breadcrumb from "../../features/Breadcrumb/Breadcrumb";

const MyServices = () => {
  const allCategories = [
    "all",
    ...new Set(productsData.categories.map((item) => item)),
  ];
  /* you can use state for store fetched items to easy display them on front */
  const [products, setProducts] = useState(productsData.products);
  const [buttons, setButtons] = useState(allCategories);
  const [isActive, setActive] = useState("all");

  /* passing data for single product from state */
  const product = products.map((product) => {
    return (
      <SingleProduct
        key={product._id}
        id={product._id}
        name={product.name}
        product_image={product.product_image}
        status_1={product.status_1}
        status_2={product.status_2}
        description={product.description}
        starting_price={product.starting_price}
        category={product.category}
        subscriptions={product.subscriptions}
      />
    );
  });

  /* filter by status */
  const filter = (button, event) => {
    if (button === "all") {
      setProducts(productsData.products);
      setActive(event);
      return;
    }
    const filteredData = productsData.products.filter((product) => {
      return product.category.some((category) => {
        return category.name === button;
      });
    });
    setProducts(filteredData);
    setActive(event);
    
  };

  return (
    <> 
      <Breadcrumb category={isActive} categoryLink="/products/" />
      <div className={styles.wrapper}>
        <div className={styles.categories_container}>
          <ul>
            {buttons.map((cat, i) => {
              return (
                <li
                  key={i}
                  type="button"
                  id={cat}
                  onClick={(e) => filter(cat, e.target.id)}
                  className={cat === isActive ? `${styles.active}` : ""}
                >
                  {cat}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className={styles.container}>
        
        <h4>{isActive} Services</h4>

        <div className={styles.products}>{product}</div>
      </div>
    </>
  );
};

export default MyServices;
