import React from "react";
import styles from "./ProductDetails.module.scss";
import { useParams } from "react-router-dom";
import productsData from "../../../data";
import Breadcrumb from "../../features/Breadcrumb/Breadcrumb";

function ProductDetails() {
  const { productId } = useParams();
  const thisProduct = productsData.products.find(
    (prod) => prod._id === productId
  );

  const breadcrumbCategory = thisProduct.category.map((cat) => cat.name);
  const breadcrumbPushedCategory = breadcrumbCategory.join(" / ");

  const productPushedCategory = breadcrumbCategory.join(" & ");

  return (
    <>
      <Breadcrumb
        category={breadcrumbPushedCategory}
        categoryLink={"/products/"}
        productName={thisProduct.name}
        productLink={"/products/" + productId}
        productId={productId}
      />
      <div className={styles.container}>
        <div className={styles.big_container}>
          <div className={styles.name}>
            <h2>{thisProduct.name}</h2>
            <p>{productPushedCategory}</p>
          </div>
          <div className={styles.images}>
            {thisProduct.images.map((image) => {
              return <img src={image.link} alt="...loading" />;
            })}
            {/* <img src={image1} alt="...loading" />
            <img src={image2} alt="...loading" />
            <img src={image3} alt="...loading" /> */}
          </div>
          <ul className={styles.description}>
            {thisProduct.descriptions
              ? thisProduct.descriptions.map((description) => {
                  return <li>{description}</li>;
                })
              : null}
          </ul>
        </div>
        <div className={styles.small_container}>
          <div className={styles.purchase_info}>
            {thisProduct.subscriptions.map((subscription) => {
              return (
                <div className={styles.info}>
                  <h5>
                    {subscription.time} - <span>£{subscription.price}</span>
                  </h5>
                  <a
                    href={subscription.shoppy_link}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <div className={styles.purchase_button}>Buy Now</div>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
export default ProductDetails;
