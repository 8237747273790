import React, { useState } from "react";
import styles from "./Faq.module.scss";
import { NavLink, Link } from "react-router-dom";

import qa from "../../../faq";

export default function Faq() {
  const [faq, setFaq] = useState(qa);
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  return (
    <section id="faq">
      <div className={styles.breadcrumb}>
        <NavLink to="/" end>
          Home
        </NavLink>
        <p>{">"}</p>
        <NavLink className={styles.active} to="/faq/">
          Faq
        </NavLink>
      </div>
      <div className={styles.container}>
        <div className={styles.box}>
          {faq.map((item, i) => (
            <div className={styles.questions} key={i}>
              <div
                className={
                  `${styles.title}` +
                  " " +
                  `${selected === i ? `${styles.active}` : ""}`
                }
                onClick={() => toggle(i)}
              >
                {" "}
                <div className={styles.category}>{item.category}</div>
                <h3>{item.question}</h3>
                <div className={styles.arrow}>
                  {selected === i ? (
                    <svg
                      width="29"
                      height="19"
                      viewBox="0 0 29 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 17L14.5 4L27 17"
                        stroke="#ffffff"
                        strokeWidth="5"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="29"
                      height="19"
                      viewBox="0 0 29 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 2L14.5 15L27 2"
                        stroke="#ffffff"
                        strokeWidth="5"
                      />
                    </svg>
                  )}
                </div>
              </div>
              <div
                className={
                  `${styles.content}` +
                  " " +
                  `${selected === i ? styles.show : ""}`
                }
              >
                <p>{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
