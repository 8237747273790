import { HashRouter, Routes, Route } from "react-router-dom";

import MainLayout from "./components/layout/MainLayout/MainLayout";
import Homepage from "./components/pages/Homepage/Homepage";
import MyServices from "./components/pages/MyServices/MyServices";
import ProductDetails from "./components/pages/ProductDetails/ProductDetails";
import Faq from "./components/features/Faq/Faq";
function App() {
  return (
    <HashRouter>
      <MainLayout>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/products" element={<MyServices />} />
          <Route
            path="products/:productId"
            element={<ProductDetails productId />}
          />
          <Route path="faq" element={<Faq />} />
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
      </MainLayout>
    </HashRouter>
  );
}

export default App;
