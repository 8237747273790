import { faL } from "@fortawesome/free-solid-svg-icons";

const WARZONE = "Warzone 2";
const COLDWAR = "Cold War";
const MODERNWARFARE = "MW";
const MODERNWARFARE2 = "MW2";
const MODERNWARFARE3 = "MW3";
const BO6 = "Black Ops 6";
const VANGUARD = "Vanguard";
const BLACKOPS4 = "Black Ops 4";
const FORTNITE = "Fortnite";
const OTHER = "Other";

const data = {
  products: [
    {
      _id: "1",
      name: "Advanced UAV Tool",
      product_image: "images/products/product_1.png",
      status_1: "active",
      status_2: "undetected",
      category: [{ name: COLDWAR }],
      starting_price: "9.99",
      images: [
        {
          link: "https://i.imgur.com/Iojv7B7.gif",
        },
        {
          link: "https://i.imgur.com/wB6yy4s.gif",
        },
      ],
      subscriptions: [
        {
          price: "20",
          time: "Lifetime",
          shoppy_link:
            "https://warzonecheats.net/product/worlds-1st-cold-war-lifetime-advanced-uav-tool/",
        },
        {
          price: "9.99",
          time: "1 Month",
          shoppy_link:
            "https://warzonecheats.net/product/worlds-1st-cold-war-lifetime-advanced-uav-tool/",
        },
      ],
    },
    {
      _id: "5",
      name: "Shadow Ban Fix - Hyper Spoofer",
      product_image: "images/products/product_8.png",
      status_1: "active",
      status_2: "undetected",
      category: [
        { name: COLDWAR },
        { name: MODERNWARFARE2 },
        { name: WARZONE },
      ],
      starting_price: "25.00",
      images: [
        {
          link: "https://i.imgur.com/Zy0pZje.gif",
        },
        {
          link: "https://i.imgur.com/md6S7mn.gif",
        },
        {
          link: "https://i.imgur.com/aRW36TA.gif",
        },
      ],
      subscriptions: [
        {
          price: "25",
          time: "1 Month",
          shoppy_link:
            "https://warzonecheats.net/product/shadow-ban-fix-spoofer-31day-works-on-modern-warfare-2-warzone2/",
        },
      ],
    },
    {
      _id: "26",
      name: "Permanent HWID Change",
      bestseller: true,
      product_image: "images/products/product_27.png",
      status_1: "active",
      status_2: "undetected",
      category: [
        { name: BO6 },
        { name: MODERNWARFARE },
        { name: COLDWAR },
        { name: BLACKOPS4 },
        { name: VANGUARD },
        { name: OTHER },
      ],
      starting_price: "120",
      images: [
        {
          link: "https://i.imgur.com/Mext4x9.gif",
        },
        {
          link: "https://i.imgur.com/w8ubW9m.gif",
        },
      ],
      subscriptions: [
        {
          price: "120",
          time: "ONE HWID UNBAN",
          shoppy_link:
            "https://warzonecheats.net/product/permanent-hwid-change-completely-unban-your-pc-for-bo6/",
        },
      ],
    },
    {
      _id: "29",
      name: "Private MW3 Cheat",
      product_image: "images/products/product_30.png",
      bestseller: true,
      status_1: "active",
      status_2: "undetected",
      category: [{ name: MODERNWARFARE3 }],
      starting_price: "15.00",
      images: [
        {
          link: "https://i.imgur.com/Xe7PVFN.gif",
        },
      ],
      subscriptions: [
        {
          price: "15.00",
          time: "1 Month",
          shoppy_link:
            "https://warzonecheats.net/product/1-month-private-mw3-cheat-with-spoofer-blocker-unlock-tool-full-controller-support/",
        },
        {
          price: "60.00",
          time: "Lifetime",
          shoppy_link:
            "https://warzonecheats.net/product/1-month-private-mw3-cheat-with-spoofer-blocker-unlock-tool-full-controller-support/",
        },
      ],
    },
    {
      _id: "33",
      name: "Cold war cheat",
      product_image: "images/products/product_34.png",
      bestseller: true,
      status_1: "active",
      status_2: "undetected",
      category: [{ name: COLDWAR }],
      starting_price: "15.00",
      images: [
        {
          link: "https://i.imgur.com/1GBgrJp.gif",
        },
        {
          link: "https://i.imgur.com/pYlxP1v.gif",
        },
      ],
      subscriptions: [
        {
          price: "15",
          time: "1 Month",
          shoppy_link:
            "https://warzonecheats.net/product/cold-war-cheat-espaimbotzombie-featuresunlock-all-tool-swapper-and-modded-guns/",
        },
        {
          price: "45",
          time: "Lifetime",
          shoppy_link:
            "https://warzonecheats.net/product/cold-war-cheat-espaimbotzombie-featuresunlock-all-tool-swapper-and-modded-guns/",
        },
      ],
    },
    // {
    //   _id: "34",
    //   name: "Cheat with spoofer & blocker + Unlock Tool",
    //   product_image: "images/products/product_35.png",
    //   bestseller: true,
    //   status_1: "active",
    //   status_2: "undetected",
    //   category: [{ name: MODERNWARFARE3 }],
    //   starting_price: "35.00",
    //   images: [
    //     {
    //       link: "https://i.imgur.com/ZAxoTcC.gif",
    //     },
    //     {
    //       link: "https://i.imgur.com/EJQ37cH.gif",
    //     },
    //   ],
    //   subscriptions: [
    //     {
    //       price: "35",
    //       time: "1 Month",
    //       shoppy_link:
    //         "https://hitmansservices.mysellix.io/product/6598806471f8f",
    //     },
    //   ],
    // },
    {
      _id: "35",
      name: "Warzone 2 Accounts",
      product_image: "images/products/product_10.png",
      bestseller: true,
      status_1: "active",
      status_2: "undetected",
      category: [{ name: WARZONE }],
      starting_price: "2.00",
      images: [
        {
          link: "https://imagedelivery.net/A5gbiev6F8AaBvp6M146Kw/af882aae-bf66-466a-d491-f60d01770b00/productCard",
        },
      ],
      subscriptions: [
        {
          price: "2",
          time: "1 Account",
          shoppy_link:
            "https://warzonecheats.net/product/the-best-warzone2-ready-made-accounts-automatic-24-7-delivery/",
        },
      ],
    },
    {
      _id: "36",
      name: "Project Movies",
      product_image: "images/products/product_7.png",
      bestseller: false,
      status_1: "active",
      status_2: "undetected",
      category: [{ name: OTHER }],
      starting_price: "10.00",
      images: [
        {
          link: "https://imagedelivery.net/A5gbiev6F8AaBvp6M146Kw/e3e1ce25-decb-45a3-b3c8-413915f8f700/productCard",
        },
      ],
      subscriptions: [
        {
          price: "10",
          time: "1 Month",
          shoppy_link:
            "https://warzonecheats.net/product/project-movies/",
        },
        {
          price: "60",
          time: "1 Year",
          shoppy_link:
            "https://warzonecheats.net/product/project-movies/",
        },
      ],
    },
    {
      _id: "38",
      name: "Private BO6 Cheat with spoofer & blocker",
      product_image: "images/products/product_38.png",
      bestseller: true,
      status_1: "active",
      status_2: "undetected",
      category: [{ name: BO6 }],
      starting_price: "25.00",
      images: [
        {
          link: "https://i.imgur.com/dlp5Lns.gif",
        },
        {
          link: "https://i.imgur.com/tKfdT2R.gif",
        },
      ],
      subscriptions: [
        {
          price: "25",
          time: "1 Month",
          shoppy_link:
            "https://warzonecheats.net/product/private-bo6-cheat-with-spoofer-blocker/",
        },
        {
          price: "99",
          time: "Lifetime",
          shoppy_link:
            "https://warzonecheats.net/product/private-bo6-cheat-with-spoofer-blocker/",
        },
      ],
    },
    {
      _id: "39",
      name: "Reseller Tool Panel",
      product_image: "images/products/product_32.png",
      bestseller: false,
      status_1: "active",
      status_2: "undetected",
      category: [{ name: OTHER }],
      starting_price: "300.00",
      images: [
        {
          link: "https://cdn.sellix.io/storefront/704c1d51ba4fcd3f37423d4165cfe7611c82bf29/68747470733a2f2f692e696d6775722e636f6d2f415135736a38372e676966",
        },
        {
          link: "https://cdn.sellix.io/storefront/4238fc54c7d19bca06c0d6ce9f38aed0b8b4b83e/68747470733a2f2f692e696d6775722e636f6d2f7064304a504b662e676966",
        },
        {
          link: "https://cdn.sellix.io/storefront/08aaf48cbbec397e39a6722f4fdd22454f96af2c/68747470733a2f2f692e696d6775722e636f6d2f434542503971302e676966",
        },
      ],
      subscriptions: [
        {
          price: "300",
          time: "Panel",
          shoppy_link:
            "https://warzonecheats.net/product/reseller-tool-panel/",
        }
      ],
    },
    // {
    //   _id: "37",
    //   name: "Mastery Plan",
    //   product_image: "images/products/product_32.png",
    //   bestseller: false,
    //   status_1: "active",
    //   status_2: "undetected",
    //   category: [
    //     { name: MODERNWARFARE2 },
    //     { name: MODERNWARFARE3 },
    //     { name: COLDWAR },
    //     { name: BLACKOPS4 },
    //     { name: MODERNWARFARE },
    //     { name: WARZONE },
    //   ],
    //   starting_price: "150.00",
    //   images: [
    //     {
    //       link: "https://cdn.sellix.io/storefront/f5a207b28ee23c97bc4301cdc47026945500158e/68747470733a2f2f692e696d6775722e636f6d2f4649306f3965692e676966",
    //     },
    //   ],
    //   subscriptions: [
    //     {
    //       price: "150",
    //       time: "Lifetime",
    //       shoppy_link:
    //         "https://hitmansservices.mysellix.io/product/62b100536a588",
    //     },
    //   ],
    // },
  ],
  categories: [
    "Black Ops 6",
    "MW3",
    "MW2",
    "MW",
    "Warzone 2",
    "Cold War",
    "Vanguard",
    "Fortnite",
    "Other",
  ],
};

export default data;
