import React from "react";
import styles from "./Footer.module.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";


library.add(fab);

const Footer = () => (
  <footer>
    <div className={styles.container}>
      <div className={styles.item}>
        <ul>
          <li>
            <NavLink
              exact
              to="/"
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to="/products"
            >
              Services
            </NavLink>
          </li>
          <li>
            <a
              href="https://t.me/joinchat/ch_lEBF4GoY5NWM0"
              rel="noreferrer"
              target="_blank"
            >
              Telegram
            </a>
          </li>
          <li>
            <a
              href="/discord"
              rel="noreferrer"
              target="_blank"
            >
              Support
            </a>
          </li>
          <li>
            <a
              href="https://forum.hitmansservices.com/"
              rel="noreferrer"
              target="_blank"
            >
              Forum
            </a>
          </li>
        </ul>

        <ul className={styles.payments}>
          <li>
            <p>Pay with</p>
          </li>
          <li>
            <FontAwesomeIcon icon={["fab", "cc-paypal"]} size="2x" />
          </li>
          <li>
            <FontAwesomeIcon icon={faCreditCard} size="2x" />
          </li>
          <li>
            <FontAwesomeIcon icon={["fab", "bitcoin"]} size="2x" />
          </li>
        </ul>
        <ul>
          <li>
            <a
              href="https://forum.hitmansservices.com/"
              rel="noreferrer"
              target="_blank"
            >
              <div className={styles.button1}>Forum</div>
            </a>
          </li>
          <li>
            <a
              href="https://t.me/joinchat/ch_lEBF4GoY5NWM0"
              rel="noreferrer"
              target="_blank"
            >
              <div className={styles.button2}>Join Telegram for Updates</div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <section className={styles.legal}>
      <ul className={styles.legal_list}>
        <li>
          <p>&copy; 2022 Copyright Hitman Service.</p>
        </li>
      </ul>
    </section>
  </footer>
);

export default Footer;
